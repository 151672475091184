.bggrey:nth-of-type(even) {
    background: #f8f8f8;
  }
  .txt-right{
      text-align:right;
  }

  .txt-right button{
     padding:0;
  }

  .txt-right svg{
   width:0.6em!important;
 }

 .addons-title{
  padding-left: 20px;
 }