.webshell-container {
  position: relative;
}

.webshell-container .launch-external-shell {
  position: absolute;
  top: 5px;
  right: 20px;
  padding: 5px;
  background-color: #ffffff;
}
